import React, { useState, useEffect, useRef } from "react";
import "./our_history_slider.css";
import { motion } from "framer-motion";

const OurHistory = () => {
  const [sectionCount, setSectionCount] = useState(1);
  const [animationClass, setAnimationClass] = useState('');
  const [animationImgClass, setAnimationImgClass] = useState('');

  const prevSectionCountRef = useRef();

  useEffect(() => {
    prevSectionCountRef.current = sectionCount;
  });

  useEffect(() => {
    setAnimationClass('stepper-page-right-p');
    setAnimationImgClass('stepper-page-right-img');
    const timer = setTimeout(() => {setAnimationClass(''); setAnimationImgClass('')}, 700);
    return () => clearTimeout(timer);
  }, [sectionCount]);

  const data = [
    {
      year:'1964',
      p:"Our story begins in 1964, with Chemical Industries (Colombo) Limited, now known as CIC Holdings being incorporated, and listed in the Colombo Brokers Association.",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'1968-1974',
      p:"The year 1968 saw CIC commence Operations of the Ratmalana Factory with the Agrochemicals Plant, and the formation of Chemanex PLC in 1974.",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'1991',
      p:"Expanded its operations by incorporating CISCO Specialty Packaging (Private) Limited to manufacture PET Bottles",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'1992',
      p:"Purchase of Fertilizer business from British American Tobacco and incorporation of CIC Fertilizers (Private) Limited (now known as CIC Agri Businesses (Private) Limited)",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'1993',
      p:"Investment in CIAL where we produced writing instruments. Head office shifted to own premises",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'1995',
      p:"Incorporation of CIC Paints (Private) Limited (now known as Akzo Nobel Paints Lanka (Private) Limited)",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'1998',
      p:"Lease of Hingurakgoda Farm (1300 acres).",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'2000',
      p:"In the 1990s CIC expanded its operations by incorporating CISCO Speciality Packaging (Pvt.) Ltd. our PET Bottles manufacturing business in 1991 and also incorporating CIC Fertilizers (Pvt.) Ltd (now CIC Agri Businesses (Pvt.) Ltd) upon purchasing the Fertiliser Business from British American Tobacco in 1992. In 1993, CIC invested in CIAL - our gateway to produce writing instruments, and also proudly shifted the Company’s Head Office to our own premises at 199, Kew Road, Colombo 2. 1995 saw the incorporation of CIC Paints (Pvt) Ltd, now known as Akzo Nobel Paints Lanka (Pvt.) Ltd.",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'2001',
      p:"Lease of Pelwehera Seed Farm (650 acres).",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'2002',
      p:"Purchase of Nutrina (Pvt) Ltd from Cargills Inc. USA and incorporation of CIC Feeds (Pvt) Ltd.",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'2003',
      p:"Investment in Link Natural Products (Pvt) Ltd.",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'2006',
      p:"Incorporation of CIC CropGuard (Pvt) Limited",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'2007',
      p:"Incorporation of CIC Agri Produce Export (Pvt) Ltd",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'2010',
      p:"In 2010, CIC invested in Chemcel (Pvt.) Ltd and shifted the Writing Instruments factory to our own premises in Piliyandala.",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'2011',
      p:"The year 2011 witnessed a very significant change with the change of the Company name from Chemical Industries (Colombo) PLC to CIC Holdings PLC.",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'2012',
      p:"Expanding the Company’s presence in the healthcare sector, CIC Invested in Ceylinco Pharmaceuticals Limited (now known as CIC Lifesciences Limited) in 2012. Incorporation of CIC Dairies",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'2014',
      p:"The year 2014 saw the Company go through a complete organisational restructure and the shifting of the Crop Protection Manufacturing Facility from Ratmalana to Godagama.",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'2016',
      p:"In 2016 the Company incorporated CIC Grains (Private) Ltd. to process inputs for the feed industry and expand its presence in that sector. The PVA factory was shifted from Ratmalana to Godagama during the same year.",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'2017',
      p:"Purchase of Unipower (Private) Limited.",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'2020',
      p:"Transformation of the organizational structure in accordance with the 'CIC Re-Strategising 2020' by segregating the business into five core clusters; Crop Solutions, Agri Produce, Livestock Solutions, Health & Personal Care and Industrial Solutions",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'2021',
      p:"Purchase of Bio Technologies Ltd.",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'2022',
      p:"Acquisition of Asiavet Lifesence (Pvt) Limited",
      imagePath : "https://fakeimg.pl/400x300"
    },
    {
      year:'2023',
      p:"John Keells Holdings (JKH) became an associate of CIC Holdings PLC",
      imagePath : "https://fakeimg.pl/400x300"
    }
  ]
  

  return (
    <div class="stepper-conatiner">
      <div className="slider-container">
        <div className="progress-bar-container">

          {/* First Line List Items*/}
          <ul className="progress-01" style={{top:0}}>
            <li class={`step ${sectionCount > 0 ? 'active' : ''} `} onClick={()=>setSectionCount(1)}><div class="step-inner">1964</div></li>
            <li class={`step ${sectionCount > 1 ? 'active' : ''} `} onClick={()=>setSectionCount(2)}><div class="step-inner">1968 – 1974</div></li>
            <li class={`step ${sectionCount > 2 ? 'active' : ''} `} onClick={()=>setSectionCount(3)}><div class="step-inner">1991</div></li>
            <li class={`step ${sectionCount > 3 ? 'active' : ''} `} onClick={()=>setSectionCount(4)}><div class="step-inner">1992</div></li>
            <li class={`step ${sectionCount > 4 ? 'active' : ''} `} onClick={()=>setSectionCount(5)}><div class="step-inner">1993</div></li>
            <li class={`step ${sectionCount > 5 ? 'active' : ''} `} onClick={()=>setSectionCount(6)}><div class="step-inner">1995</div></li>
            <li class={`step ${sectionCount > 6 ? 'active' : ''} `} onClick={()=>setSectionCount(7)}><div class="step-inner">1998</div></li>
            <li class={`step ${sectionCount > 7 ? 'active' : ''} `} onClick={()=>setSectionCount(8)}><div class="step-inner">2000</div></li>
          </ul> 
            
          {/* First Line*/}
          <div className="line">
            <div 
              className="line-progress" 
              style={
                {
                  width:`${sectionCount < 9 ? sectionCount == 1 ? 3 : (100/7)*(sectionCount-1): 100 }%`, 
                  transitionDelay:`${
                    prevSectionCountRef.current >= 17 && sectionCount <= 8 ? 2.8 :
                      prevSectionCountRef.current >= 9 && sectionCount <= 8 ? 
                      prevSectionCountRef.current == 9 ? 0.8 
                      : 1.4 : 0
                  }s`
                }
              }
            ></div>
          </div>


          {/* First Base Curved line*/}
          <svg
            className="curved-connector-right curved-connector-01"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 50"
            width={155}
          >
            <path
              d="M 0,50 A 50,50 0 0,1 100,50"
              fill="none"
              stroke="#b8b8b8"
              strokeWidth="2"
            />
          </svg>

          {/* First Curved line Forward Animation */}
          {sectionCount > 8 && 
            <motion.svg
              className="curved-connector-right curved-connector-02"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 50"
              width={155}
            >
              <defs>
                <linearGradient id="gradient-id" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stopColor="#7dafe2" />
                  <stop offset="100%" stopColor="#386aab" />
                </linearGradient>
              </defs>
              <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1}}
                transition={{
                  duration: 0.5,
                  ease: "easeInOut",
                  delay:prevSectionCountRef.current === 8 ? 0 : 0.9 
                }}
                stroke="url(#gradient-id)"
                strokeWidth="2"
                fill="none"
                d="M 0,50 A 50,50 0 0,1 100,50"
              />
            </motion.svg>
          }

          {/* First Curved line Backward Animation */}
          {sectionCount <= 8 && 
            <motion.svg
              className="curved-connector-right curved-connector-02"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 50"
              width={155}
            >
              <defs>
                <linearGradient id="gradient-id" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stopColor="#7dafe2" />
                  <stop offset="100%" stopColor="#386aab" />
                </linearGradient>
              </defs>
              <motion.path
                initial={{ pathLength: 1 }}
                animate={{ pathLength: 0}}
                transition={{
                  duration: 0.5,
                  ease: "linear",
                  delay:prevSectionCountRef.current == 9 ? 0.3 : 
                        prevSectionCountRef.current >= 17 && sectionCount <= 16 ? 2.3 
                        : 0.9
                }}
                stroke="url(#gradient-id)"
                strokeWidth="2"
                fill="none"
                d="M 0,50 A 50,50 0 0,1 100,50"
              />
            </motion.svg>
          }

          {/* Second Line List Items*/}
          <ul className="progress-2" style={{top:'150px'}}>
            <li class={`step ${sectionCount > 15 ? 'active' : ''} `} onClick={()=>setSectionCount(16)}><div class="step-inner">2012</div></li>
            <li class={`step ${sectionCount > 14 ? 'active' : ''} `} onClick={()=>setSectionCount(15)}><div class="step-inner">2011</div></li>
            <li class={`step ${sectionCount > 13 ? 'active' : ''} `} onClick={()=>setSectionCount(14)}><div class="step-inner">2010</div></li>
            <li class={`step ${sectionCount > 12 ? 'active' : ''} `} onClick={()=>setSectionCount(13)}><div class="step-inner">2007</div></li>
            <li class={`step ${sectionCount > 11 ? 'active' : ''} `} onClick={()=>setSectionCount(12)}><div class="step-inner">2006</div></li>
            <li class={`step ${sectionCount > 10 ? 'active' : ''} `} onClick={()=>setSectionCount(11)}><div class="step-inner">2003</div></li>
            <li class={`step ${sectionCount > 9 ? 'active' : ''} `} onClick={()=>setSectionCount(10)}><div class="step-inner">2002</div></li>
            <li class={`step ${sectionCount > 8 ? 'active' : ''} `} onClick={()=>setSectionCount(9)}><div class="step-inner">2001</div></li>
          </ul> 
          
          {/* Second Line*/}
          <div className="line line-2">
            <div 
              className="line-progress line-progress-2" 
              style={
                {
                  width:`${(sectionCount > 8 && sectionCount < 17 )? sectionCount == 9 ? 3 : (100/7)*(sectionCount-9): sectionCount > 16 ? 100: 0}%`,
                  transitionDelay:`${
                    prevSectionCountRef.current <= 8 && sectionCount >= 9 ? 
                      prevSectionCountRef.current == 8 && sectionCount == 9 ? 
                      0 : 1.4 : 
                      prevSectionCountRef.current >= 17 && sectionCount <= 16 ? 1.4 : 0
                  }s`
                }
              }
            ></div>
          </div>

          {/* Second Curved line Forward Animation */}
          {sectionCount > 16 && 
            <motion.svg
              className="curved-connector-left curved-connector-02"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 50"
              width={155}
            >
              <defs>
                <linearGradient id="gradient-id" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stopColor="#7dafe2" />
                  <stop offset="100%" stopColor="#386aab" />
                </linearGradient>
              </defs>
              <motion.path
                initial={{ pathLength: 0, pathOffset:1 }}
                animate={{ pathLength: 1 , pathOffset:0 }}
                transition={{
                  duration: 0.5,
                  ease: "easeInOut",
                  delay:  prevSectionCountRef.current == 16 ? 0 : 
                          prevSectionCountRef.current > 8 ? 0.9 : 2.3
                }}
                stroke="url(#gradient-id)"
                strokeWidth="2"
                fill="none"
                d="M 0,50 A 50,50 0 0,1 100,50"
              />
            </motion.svg>
          }

          {/* Second Curved line Backward Animation */}
          {(sectionCount <= 16) && 
            <motion.svg
              className="curved-connector-left curved-connector-02"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 50"
              width={155}
            >
              <defs>
                <linearGradient id="gradient-id" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stopColor="#7dafe2" />
                  <stop offset="100%" stopColor="#386aab" />
                </linearGradient>
              </defs>
              <motion.path
                initial={{ pathLength: 1, pathOffset:0 }}
                animate={{ pathLength: 0, pathOffset:1 }}
                transition={{
                  duration: 0.5,
                  ease: "easeInOut",
                  delay: prevSectionCountRef.current >= 17 && 0.9
                }}
                stroke="url(#gradient-id)"
                strokeWidth="2"
                fill="none"
                d="M 0,50 A 50,50 0 0,1 100,50"
              />
            </motion.svg>
          }


          {/* Second Base Curved line */}
          <svg
            className="curved-connector-left curved-connector-01"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 50"
            width={155}
          >
            <path
              d="M 0,50 A 50,50 0 0,1 100,50"
              fill="none"
              stroke="#b8b8b8"
              strokeWidth="2"
            />
          </svg>    

          {/* Third Line List Items*/}
          <ul className="progress-3" style={{top:'305px'}}>
            <li class={`step ${sectionCount > 16 ? 'active' : ''} `} onClick={()=>setSectionCount(17)}><div class="step-inner">2014</div></li>
            <li class={`step ${sectionCount > 17 ? 'active' : ''} `} onClick={()=>setSectionCount(18)}><div class="step-inner">2016</div></li>
            <li class={`step ${sectionCount > 18 ? 'active' : ''} `} onClick={()=>setSectionCount(19)}><div class="step-inner">2017</div></li>
            <li class={`step ${sectionCount > 19 ? 'active' : ''} `} onClick={()=>setSectionCount(20)}><div class="step-inner">2020</div></li>
            <li class={`step ${sectionCount > 20 ? 'active' : ''} `} onClick={()=>setSectionCount(21)}><div class="step-inner">2021</div></li>
            <li class={`step ${sectionCount > 21 ? 'active' : ''} `} onClick={()=>setSectionCount(22)}><div class="step-inner">2022</div></li>
            <li class={`step ${sectionCount > 22 ? 'active' : ''} `} onClick={()=>setSectionCount(23)}><div class="step-inner">2023</div></li>
          </ul>

          {/* Third Line*/}
          <div className="line-base-3 line-3">
            <div 
              className="line-progress line-progress-3" 
              style={
                {
                  width:`${(sectionCount > 16 && sectionCount < 24 )? sectionCount == 17 ? 3 : (100/6)*(sectionCount-17): sectionCount > 23 ? 100: 0}%`,
                  transitionDelay:`${
                    prevSectionCountRef.current <= 16 && prevSectionCountRef.current >= 9 && sectionCount >= 17 ?
                      prevSectionCountRef.current ==16 && sectionCount == 17 ? 0.5 : 1.4 :
                    prevSectionCountRef.current <= 8 && sectionCount >= 17 ? 2.8 : 0
                  }s`
                }
              }
            ></div>
          </div>
          
        </div>
      </div>


      <div className="stepper-page-contents">
        <div className={`stepper-page-left ${animationImgClass}`}>
          <div className="stepper-page-left-image-container">
            <img src={data[sectionCount-1].imagePath} alt="" />
          </div>
        </div>
        <div className="stepper-page-right">
          <p className={`${animationClass}`}>
            {
              data[sectionCount-1].p
            }
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurHistory;

import React, { useEffect, useState } from 'react'
import "./aboutCIC.css"
import Vector6Arrow from "../../images/Vector6Arrow.png"
import Aos from "aos";
import "aos/dist/aos.css";
import logo from '../../images/footer-logo.png';
import bg_bottom from '../../images/bg_bottom.png'
import bg_top from '../../images/bg_top.png'
import { IoIosCloseCircle } from "react-icons/io";

export default function AboutCIC() {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleOutsideClick = (event) => {
    if (event.target.className === "aboutCICStory-modal") {
      closeModal();
    }
  };


  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);


  return (
    <div>
      <div className="aboutCIC-layout-42">
            <div className="aboutCIC-content2">
              <div className="aboutCIC-column2">
                <div className="aboutCIC-heading">
                  <h1 className="aboutCIC-heading-h1" data-aos="fade-right" data-aos-duration="700">
                    About CIC</h1>
                </div>
              </div>
              <div className="aboutCIC-column3">
                <div className="aboutCIC-text">
                  <p data-aos="fade-left" data-aos-duration="500">
                    Chemical Industries (Colombo) Limited (initially
                    a part of Imperial Chemical Industries-UK), was
                    incorporated in 1964 as a supplier of high-quality
                    chemical products for the local market. The
                    Company is now known as CIC Holdings PLC,
                    one of Sri Lanka’s leading conglomerates, and
                    over the years have become a household name
                    through our many ventures.
                  </p>


               
                  <div onClick={openModal} className='aboutCIC-target_button' data-aos="fade-left" data-aos-duration="700">
                    Read more
                    <img className="vector6arrow" alt="loading" src={Vector6Arrow} />
                  </div>
                      
                  {isModalOpen && (
                    // <div className="aboutCICStory-modal" onClick={handleOutsideClick}>
                    //   <div className="aboutCICStory-modal-content">
                    //     <span className="aboutCICStory-close" onClick={closeModal}>&times;</span>
                    //       <div>
                    //         <h1 className="aboutCICStory-modal-content-heading">Our Story</h1>

                            // <p className="aboutCICStory-modal-content-paragraph">Chemical Industries (Colombo) Limited (initially
                            //   a part of Imperial Chemical Industries-UK), was incorporated in 1964 as a supplier of high-quality 
                            //   chemical products for the local market. The Company is now known as CIC Holdings PLC,
                            //   one of Sri Lanka’s leading conglomerates, and over the years have become a household name
                            //   through our many ventures.</p>
                                      
                    //         <p className="aboutCICStory-modal-content-paragraph">Initially the Company was known for
                    //           manufacturing locally produced paints and pipes used for agriculture. Our business expansions
                    //           have taken us into several key industries in the country. While a large part of these are in the
                    //           Agri Produce and Crop Solutions sectors, we also have a presence in the Health & Personal
                    //           Care, Livestock Solutions and Industrial Solution sectors.</p>
                                     
                            // <p className="aboutCICStory-modal-content-paragraph">Our success owes itself to our drive to innovate
                            //   and this has enabled us to re-align and re-position ourselves in sectors that show high
                            //   growth. This has led us to venture into export markets, being a supplier of Agri Produce such
                            //   as rice, bananas, and fresh vegetables and some Industrial and Crop solution products.</p>
                    //       </div>
                    //   </div>
                    // </div>


                    <div
                        className="about-story-modal"
                        onClick={handleOutsideClick}
                      >
                        <div className="about-story-model-left">
                          <div className="about-story-model-left-image-container">
                            <img src={logo} alt="" />
                          </div>
                        </div>
                        <div className="about-story-model-right">
                          <div className="pop-up-close-btn" onClick={closeModal}><IoIosCloseCircle color="#001033" size={30}/></div>
                          <div className="about-story-model-left-bg-top-image-container">
                            <img src={bg_top} alt="" />
                          </div>
                          <div className="about-story-model-left-bg-bottom-image-container">
                            <img src={bg_bottom} alt="" />
                          </div>
                          
                          <div className="about-story-model-right-content">
                            <h1 className="aboutCICStory-modal-content-heading">Our Story</h1>

                             <p className="about-story-modal-content-paragraph">
                              Chemical Industries (Colombo) Limited (initially
                              a part of Imperial Chemical Industries-UK), was incorporated in 1964 as a supplier of high-quality 
                              chemical products for the local market. The Company is now known as CIC Holdings PLC,
                              one of Sri Lanka’s leading conglomerates, and over the years have become a household name
                              through our many ventures.
                             </p>

                             <p className="about-story-modal-content-paragraph">
                              Initially the Company was known for
                              manufacturing locally produced paints and pipes used for agriculture. Our business expansions
                              have taken us into several key industries in the country. While a large part of these are in the
                              Agri Produce and Crop Solutions sectors, we also have a presence in the Health & Personal
                              Care, Livestock Solutions and Industrial Solution sectors.
                             </p>

                             <p className="about-story-modal-content-paragraph">
                              Our success owes itself to our drive to innovate
                              and this has enabled us to re-align and re-position ourselves in sectors that show high
                              growth. This has led us to venture into export markets, being a supplier of Agri Produce such
                              as rice, bananas, and fresh vegetables and some Industrial and Crop solution products.
                             </p>
                          </div>
                        </div>
                      </div>
                  )}
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}

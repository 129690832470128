import React, { useRef, useState, useEffect } from "react";
import "./aboutDirectors1.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { IoIosCloseCircle } from "react-icons/io";
import bg_bottom from "../../images/bg_bottom.png";
import bg_top from "../../images/bg_top.png";
import Vector6Arrow from "../../images/Vector6Arrow.png";

const teamMembers = [
  {
    name: "S H AMARASEKERA",
    title: "Independent Non-Executive Director/Chairman",
    imageClass: "abtDir1-card1",
    popupImageClass: "abtDir1-card1-pop-up",
    description: [
      "Mr. Harsha Amarasekera, President’s Counsel was appointed to the Board of CIC on the 28th October 2005. He was appointed as Acting Chairman on the 01st of January 2014 and as Chairman on 23rd May 2014.",
      "Mr. Amarasekera is a leading luminary in the legal profession in Sri Lanka having a wide practice in the Original Courts as well as in the Appellate Courts. His fields of expertise include Commercial Law, Business Law, Securities Law, Banking Law and Intellectual Property Law.",
      "He also serves as the Chairman of several listed companies on the Colombo Stock Exchange including Sampath Bank PLC, Vallibel One PLC, Royal Ceramics Lanka PLC, Swisstek (Ceylon) PLC, Vallibel Power Erathna PLC and Swisstek Aluminium Ltd.",
      "He is also the Chairman of CIC Agri Businesses (Private) Ltd. He is an Independent Non-Executive Director of Expolanka Holdings PLC and Ambeon Capital PLC.",
    ],
  },
  {
    name: "R S CAPTAIN",
    title: "Non Independent Non-Executive Director",
    imageClass: "abtDir1-card2",
    popupImageClass: "abtDir1-card2-pop-up",
    description: [
      "Appointed to the Board on 10th March 2008. Mr. R. S. Captain is an entrepreneur and investor in Sri Lankan corporate sector, bringing with him a wealth of knowledge and over 16 years of business experience in a range of manufacturing sectors. His current business interests range from paints, garments, industrial gloves, cutting and polishing diamonds, plastics, and other packing material. He is the Co-Founder of Asia Stockbrokers, Asia Capital, Dutch Lanka Trailers, Asia Siyaka and Asian Alliance. He has served as Non-Executive Director of Hatton National Bank PLC, John Keells Holdings PLC and many other unlisted Companies. Mr. Captain was educated at the University of Miami, Florida, USA.",
    ],
  },
  {
    name: "S M ENDERBY",
    title: "Independent Non-Executive Director",
    imageClass: "abtDir1-card3",
    popupImageClass: "abtDir1-card3-pop-up",
    description: [
      "Joined CIC Board on 11th April 2013. Prior to that he has had a successful track record in private equity with Actis, a leading global emerging markets fund. Mr Enderby has led many of the most successful private equity transactions in  Sri Lanka.",
      "Post Actis, Mr. Enderby joined Hemas Holdings PLC in 2013 where he was Group Chief Executive Officer playing a pivotal role in transforming the Group into one of Sri Lanka’s largest Healthcare and Consumer focused businesses.",
      "Following his retirement in 2020 Mr. Enderby continues to invest in Sri Lanka with a particular focus on the consumer and education sectors through his role as Chairman of Ironwood Capital Partners and as a Director and advisor to a number of high growth businesses.",
      "During his more than 35 years of corporate and investing experience, he has served on the Boards of many leading companies in Sri Lanka and India. He is a Fellow Member of the Chartered Institute of Management Accountants, holds a Degree in Economics and Accounting from Queen’s University Belfast and a Master’s Degree in Development Studies from the University of Melbourne.",
    ],
  },
  {
    name: "M P JAYAWARDENA",
    title: "Independent Non-Executive Director",
    imageClass: "abtDir1-card4",
    popupImageClass: "abtDir1-card4-pop-up",
    description: [
      "Appointed Alternate Director to CIC Nominee Director on 21st May 2002, thereafter as a Director on 25th October 2008. He was a Past Deputy Chairman of Commercial Bank of Ceylon PLC. He is a Fellow Member of the Institute of Chartered Accountants of Sri Lanka. He is also the Chairman of Commercial Insurance Brokers (Private) Limited and serves on the Boards of many other private companies. He served at Zambia Consolidated Copper Mines in Africa for over 13 years in various senior positions including Head of Treasury, managing a loan portfolio in excess of US$ 3Bn. He was the Past President of The Sri Lanka Institute of Directors, Member of the Monitory Policy Consultation Committee of the Central Bank and the Chairman of the Ceylon National Chamber of Industries.",
    ],
  },
  {
    name: "P R SALDIN",
    title: "Non-Independent Non-Executive Director",
    imageClass: "abtDir1-card5",
    popupImageClass: "abtDir1-card5-pop-up",
    description: [
      "First appointed to the Board of Directors in 1995 and served as Commercial Director and Group Finance Director till 2005. During this period, he also served on the Board of Directors in many of the Subsidiaries and Associate Companies within the Group. On leaving the CIC Group he functioned as Country Controller and Group Finance Director for Shell Sri Lanka and subsequently as Group Chief Operating Officer of Browns Group of Companies and Managing Director of Browns Investment PLC.",
      "He is currently employed as Director of Paints & General Industries Limited and Director/Chief Executive Officer of Polypak Secco Limited. He was re-appointed to the Board of CIC Holdings PLC on 1st July 2016. He also functions as Chairman of Chemanex PLC and serves on the Board of Directors of Akzo Nobel Paints Lanka (Private) Limited, Link Natural Products (Private) Limited, CIC Agri Businesses (Pvt) Ltd. He is the Chairman of CISCO Specialty Packaging (Private) Limited. He also is a Director of Hatton National Bank PLC, HNB Assurance PLC and Chairman of Sithma Development (Private) Limited and serves as a Trustee of the HNB Sustainability Foundation.",
      "Rimoe Saldin is a Fellow of the Institute of Chartered Accountants of Sri Lanka.An associate of Institute of Chartered Accountants of England and Wales. He is also a Fellow of the Chartered Institute of Management Accountants in UK and a Certified Management Accountant, Australia. He is an alumni of the Asian Institute of Management Manila. He has over 26 years of top management level experience in the areas of Finance, Human Resource Development, General Management and Operations.",
    ],
  },
  {
    name: "D T S H MUDALIGE",
    title: "Independent Non-Executive Director",
    imageClass: "abtDir1-card6",
    popupImageClass: "abtDir1-card6-pop-up",
    description: [
      "Appointed to the Board on 16th October 2020, Mr. Sujeewa Mudalige counts over 30 years of experience as a Chartered Accountant. He is also a fellow member of CIMA (UK), ACCA (UK) and CPA (Australia). He has vast experience as the Audit Committee Chair in both public and private sector organizations. Mr. Mudalige recently retired as the Managing Partner of PricewaterhouseCoopers, Sri Lanka and currently serves as an Independent Non-Executive Director at CIC Feeds Group and Link Natural Products (Private) Limited. He is the Non-Executive Chair of Mercantile Services Provident Society (MSPS). He is also an Independent Non-Executive Director at National Development Bank PLC, NSBM Green University and Mahindra Ideal Finance Ltd.",
      "He is a past President of ICASL and has been a member of the Council of ICASL and of the governing board of CIMA UK- Sri Lanka Division, a Commission member of the Securities and Exchange Commission of Sri Lanka and held several such other positions locally and globally during the span of his career. He was an Independent Non-Executive Director of Hatton National Bank PLC from 2012 to 2019.",
    ],
  },
  {
    name: "J R GUNARATNE",
    title: "Independent Non-Executive Director",
    imageClass: "abtDir1-card7",
    popupImageClass: "abtDir1-card7-pop-up",
    description: [
      "Appointed to the Board on 06th January 2021, Mr. J. R. Gunaratne counts over 40 years of experience in Strategic Planning, Production & Distribution, Industrial Relations and Change Management in the Food & Beverage, Plantations and Leisure Sectors.",
      "During his career at John Keells Holdings PLC, he has held Directorships in several listed and private companies of the John Keells Group. He has been a Member of the Food Advisory Council of the Ministry of Health and a Member of the Council for Hotel & Tourism of the Employer’s Federation of Ceylon. He was the Founder Chairman of the Beverage Association of Sri Lanka.",
    ],
  },
  {
    name: "K D SENEWIRATNE",
    title: "Independent Non-Executive Director",
    imageClass: "abtDir1-card8",
    popupImageClass: "abtDir1-card8-pop-up",
    description: [
      "Appointed to the Board on 01st January 2023. Ms. Kshenuka Senewiratne is the first female Permanent Representative to the United Nations (UN) in New York and High Commissioner to the United Kingdom (UK) and also the Secretary of the Ministry of Foreign Affairs.",
      "Ms. Senewiratne a seasoned diplomat, who retired from the Public Service in 2020, counts a 35-year track record with the Sri Lanka foreign service, having held prestigious postings as Sri Lanka’s Head of Mission to the UN in Geneva and New York, UK and Thailand. She has extensive expertise in the North American, European, and Asian regions in a multitude of strategies to enhance bilateral and multilateral relations, especially through political and economic cooperation in the global context, and also ensuring the overall administrative effectiveness of the Sri Lanka Missions overseas and the Ministry of Foreign Affairs where she served. She also worked in an advisory capacity on international media, and is currently Sri Lanka’s High Commissioner to India.",
    ],
  },
];

const duplicateTeamMembers = [
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers
]

const AboutDirectors1 = () => {
  const [activeDirector, setActiveDirector] = useState(null);

  const openModal = (director) => {
    setActiveDirector(director);
  };

  const closeModal = () => {
    setActiveDirector(null);
  };

  const handleOutsideClick = (event) => {
    if (event.target.className === "about-director-modal") {
      closeModal();
    }
  };

  const carouselRef = useRef(null);
  const trackRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [speed, setSpeed] = useState(10000);
  const [position, setPosition] = useState(0);
  const [marginLeft, setMarginLeft] = useState(0.1);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (carouselRef.current) {
      observer.observe(carouselRef.current);
    }

    return () => {
      if (carouselRef.current) {
        observer.unobserve(carouselRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const track = trackRef.current;
    const cards = carouselRef.current.querySelectorAll(
      ".abtDir1-card-container"
    );

    cards.forEach((card) => {
      card.addEventListener("mouseenter", () => {
        track.style.animationPlayState = "paused";
      });
      card.addEventListener("mouseleave", () => {
        track.style.animationPlayState = "running";
      });
    });

    return () => {
      cards.forEach((card) => {
        card.removeEventListener("mouseenter", () => {
          track.style.animationPlayState = "paused";
        });
        card.removeEventListener("mouseleave", () => {
          track.style.animationPlayState = "running";
        });
      });
    };
  }, [isVisible]);

  useEffect(()=>{
    if(marginLeft <=0 ){
      setMarginLeft(0.1);
    }
  },[marginLeft])

  useEffect(() => {
    const handleResize = () => {
      const trackWidth = carouselRef.current.offsetWidth;
      setPosition((prevPosition) => prevPosition % trackWidth);
      
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNext = () => {
    // setSpeed(2500);
    setMarginLeft((prev)=> (prev + 200))
    setPosition((prevPosition) => prevPosition + 10000);
  };

  const handlePrev = () => {
    setPosition(0);
    // setSpeed(10000);
    setMarginLeft((prev)=> prev > 0 && (prev - 200))
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="abtDir1-team-slider" ref={carouselRef}>
      <div
        className="abtDir1-title"
        data-aos="fade-right"
        data-aos-duration="500"
      >
        <div className="abtDir1-heading">Board of Directors</div>
      </div>
      <div className="abtDir1-carousel-container">
        <div className="abtDir1-inner-carousel">
          <div
            className={`abtDir1-track ${isVisible ? "active" : ""}`}
            ref={trackRef}
            style={{
              marginLeft:`-${marginLeft}px`,
              animation:`${marginLeft <= 0 ? 'none' : isVisible && 'slide 10000s infinite linear'}`,
              animationDuration: `${speed}s`,
            }}
          >
            {duplicateTeamMembers.map((member, i) => (
              <div
                className="abtDir1-card-container"
                key={i}
                data-aos="zoom-in"
                data-aos-duration="900"
              >
                <div className={`abtDir1-card ${member.imageClass}`}>
                  <div className="abtDir1-card-content">
                    <h3>{member.name}</h3>
                    <p>{member.title}</p>
                    <div
                      onClick={() => openModal(member)}
                      className="director-read-more1"
                    >
                      Read More
                      <img
                        className="vector6arrow"
                        alt="loading"
                        src={Vector6Arrow}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="abtDir-left"></div>
          <div className="abtDir-right"></div>

          <div className="abtDir1-nav">
            <button
              className="abtDir1-prev"
              onClick={handlePrev}
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <i className="fas fa-arrow-left fa-2x"></i>
            </button>
            <button
              className="abtDir1-next"
              onClick={handleNext}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <i className="fas fa-arrow-right fa-2x"></i>
            </button>
          </div>

          
        </div>
      </div>

      {/* Modal Outside the Carousel */}
      {activeDirector && (
        <div className="about-director-modal1" onClick={handleOutsideClick}>
          <div className="about-director-model-left1">
            <div
              className={`about-director-model-director-image ${activeDirector.popupImageClass}`}
            ></div>
          </div>
          <div className="about-director-model-right1">
            <div className="pop-up-close-btn1" onClick={closeModal}>
              <IoIosCloseCircle color="#001033" size={30} />
            </div>
            <div className="about-director-model-left-bg-top-image-container1">
              <img src={bg_top} alt="" />
            </div>
            <div className="about-director-model-left-bg-bottom-image-container1">
              <img src={bg_bottom} alt="" />
            </div>

            <div className="about-director-model-right-content1">
              <h1 className="aboutCICStory-modal-content-heading1">
                {activeDirector.name}
              </h1>

              {activeDirector.description.map((paragraph, idx) => (
                <p
                  key={idx}
                  className="about-director-modal-content-paragraph1"
                >
                  {paragraph}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutDirectors1;

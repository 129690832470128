import React, { useRef, useState, useEffect } from "react";
import "./aboutManagement2.css";
import Aos from "aos";
import "aos/dist/aos.css";

const teamMembers = [
  { name: "MR. AROSHAN SERESINHE", title: "Group Chief Executive Officer", imageClass: "mgt2-card1" },
  { name: "DR. DEVAPRIYA NUGAWELA", title: "Chairman - Link Natural Products (Private) Limited", imageClass: "mgt2-card2" },
  { name: "MR. WARUNA MADAWANARACHCHI", title: "Managing Director - Agri Cluster", imageClass: "mgt2-card3" },
  { name: "MR. JAYANTHA RAJAPAKSE", title: "Chairman/Managing Director Unipower (Private) Limited", imageClass: "mgt2-card4" },
  { name: "MS. ERANDI WICKRAMAARACHCHI", title: "Group Chief Financial Officer", imageClass: "mgt2-card5" },
  { name: "MR. ARUNA JAYASEKERA", title: "Group Head of Human Resources", imageClass: "mgt2-card6" },
  { name: "MR. VIRAJ MANATUNGA", title: "Group Chief Strategy Officer / Divisional Director - Pharma", imageClass: "mgt2-card7" },
  { name: "MS. PRASADI SAMARAKOON", title: "Head of Group Treasury/Divisional Director - Group Finance", imageClass: "mgt2-card8" },
  { name: "MS. RAMANI SAMARASUNDERA", title: "Group Chief Marketing Officer", imageClass: "mgt2-card9" },
  { name: "MR. AJITH WEERASINGHE", title: "Chief Executive Officer - CIC Feeds Group", imageClass: "mgt2-card10" },
  { name: "MR. PRASAD WEGIRIYA", title: "Director/Chief Executive Officer - CISCO Speciality Packaging (Private) Limited", imageClass: "mgt2-card12" },
  { name: "MR. THUSHARA YATIGAMMANA", title: "Chief Operating Officer Chemanex PLC / Divisional Director -Industrial Solutions", imageClass: "mgt2-card13" },
  { name: "MR. HASITHA AHANGAMA", title: "Divisional Director - Healthcare - Medical Devices and Diagnostics", imageClass: "mgt2-card14" },
  { name: "MR. MAHESH WANNINAYAKE", title: "Divisional Director - Crop Solutions", imageClass: "mgt2-card15" },
  { name: "MR. ANJANA LEELARATHNE", title: "Deputy Managing Director - Agri Cluster", imageClass: "mgt2-card17" },
  { name: "MR. VIDURA GAMAGE", title: "Director/Chief Operating Officer CIC CropGuard (Private) Limited", imageClass: "mgt2-card18" },
  { name: "MR. RANJITH BANDARA", title: "Chief Operating Officer - Unipower (Private) Limited", imageClass: "mgt2-card19" },
  { name: "MS. THILINI WEGODAPOLA", title: "General Manager - Group Legal", imageClass: "mgt2-card20" },
  { name: "MR. NADEERA WIJENAYAKE", title: "Divisional Director - Group IT", imageClass: "mgt2-card21" },
  { name: "MR. POOVENDRAN SIVATHAVENDRAN", title: "General Manager - RPC & PVA Plant", imageClass: "mgt2-card22" },
  { name: "MS. DAMODARA WICKRAMANAYAKE", title: "Chief Financial Officer - CIC Capital / Chemanex PLC", imageClass: "mgt2-card23" },
];

const duplicateTeamMembers = [
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,
  ...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers,...teamMembers
]


const AboutManagement2 = () => {
  const carouselRef = useRef(null);
  const trackRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [speed, setSpeed] = useState(200); 
  const [position, setPosition] = useState(0); 
  const [marginLeft, setMarginLeft] = useState(0.1);

  useEffect(()=>{
    if(marginLeft <=0 ){
      setMarginLeft(0.1);
    }
  },[marginLeft])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (carouselRef.current) {
      observer.observe(carouselRef.current);
    }

    return () => {
      if (carouselRef.current) {
        observer.unobserve(carouselRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const track = trackRef.current;
    const cards = carouselRef.current.querySelectorAll(".mgt2-card-container");

    cards.forEach((card) => {
      card.addEventListener("mouseenter", () => {
        track.style.animationPlayState = "paused";
      });
      card.addEventListener("mouseleave", () => {
        track.style.animationPlayState = "running";
      });
    });

    return () => {
      cards.forEach((card) => {
        card.removeEventListener("mouseenter", () => {
          track.style.animationPlayState = "paused";
        });
        card.removeEventListener("mouseleave", () => {
          track.style.animationPlayState = "running";
        });
      });
    };
  }, [isVisible]);

  useEffect(() => {
    const handleResize = () => {
      const trackWidth = carouselRef.current.offsetWidth;
      setPosition((prevPosition) => prevPosition % trackWidth); 
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNext = () => {
    // setSpeed(2500);
    setMarginLeft((prev)=> (prev + 200))
    setPosition((prevPosition) => prevPosition + 10000);
  };

  const handlePrev = () => {
    setPosition(0);
    // setSpeed(10000);
    setMarginLeft((prev)=> prev > 0 && (prev - 200))
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);


  return (
    <div className="mgt2-team-slider" ref={carouselRef}>
      <div className="mgt2-title" data-aos="fade-right" data-aos-duration="500">
        <div className="mgt2-heading">Management Team</div>
      </div>
      <div className="mgt2-carousel-container">
        <div className="mgt2-inner-carousel">
          <div
            className={`mgt2-track ${isVisible ? "active" : ""}`}
            ref={trackRef}
            style={{
              marginLeft:`-${marginLeft}px`,
              animation:`${marginLeft <= 0 ? 'none' : isVisible && 'slide 20000s infinite linear'}`,
              animationDuration: `${speed}s`,
            }}
          >
            {duplicateTeamMembers.map((member, i) => (
              <div className="mgt2-card-container" key={i} data-aos="fade-up" data-aos-duration="900">
                <div className={`mgt2-card ${member.imageClass}`}>
                  <div className="mgt2-card-content">
                    <h3>{member.name}</h3>
                    <p>{member.title}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
            
          <div className="mgt2-left"></div>
          <div className="mgt2-right"></div>


          <div className="mgt2-nav">
            <button className="mgt2-prev" onClick={handlePrev} data-aos="fade-left" data-aos-duration="800">
              <i className="fas fa-arrow-left fa-2x"></i>
            </button>
            <button className="mgt2-next" onClick={handleNext} data-aos="fade-right" data-aos-duration="800">
              <i className="fas fa-arrow-right fa-2x"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutManagement2;


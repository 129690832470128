import React, { useEffect, useState } from 'react';
import './aboutHistory2.css';
import Aos from "aos";
import "aos/dist/aos.css";
import OurHistory from './slider/our_history_slider';

export default function AboutHistory1() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="about2-history-container">
      <h2 className="history2-title" data-aos="fade-right" data-aos-duration="700">Key Milestones</h2>
      <OurHistory />
    </div>
  );
}

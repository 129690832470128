import React, { useEffect } from 'react'
import "./aboutChairman.css"
import ChairmanImg from "../../images/chairman.webp"
import Aos from "aos";
import "aos/dist/aos.css";

export default function AboutChairman() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  
  return (
    <div>
      <div className="chairmanCIC-testimonial-13">
            <div className="chairmanCIC-content4">
              <img
                data-aos="fade-right" data-aos-duration="700"
                className="chairmanCIC-placeholder-image-icon1"
                alt="loading"
                src={ChairmanImg}
              />
              <div className="chairmanCIC-content5">
                <div className="chairmanCIC-heading" data-aos="fade-left" data-aos-duration="500">
                  Message From Chairman
                </div>
                
                <div className="chairmanCIC-quote" data-aos="fade-left" data-aos-duration="700">
                  <p className="chairmanCIC-the-board-plays">
                    The Board plays a key role in formulating, enabling and
                    monitoring strategy of the Group, giving particular emphasis
                    to driving growth as well as to the positioning of the Group
                    taking in to account the forces shaping the business
                    environment, globally and locally.
                  </p>
                  <p className="chairmanCIC-the-board-plays">&nbsp;</p>
                  <p className="chairmanCIC-the-board-plays">
                    The Board plays a key role in formulating, enabling and
                    monitoring strategy of the Group, giving particular emphasis
                    to driving growth as well as to the positioning of the Group
                    taking in to account the forces shaping the business
                    environment, globally and locally.
                  </p>
                </div>
                <div className="chairmanCIC-avatar" data-aos="fade-left" data-aos-duration="700">
                  <div className="chairmanCIC-avatar-content">
                    <div className="chairmanCIC-text1">Mr. S. H. Amarasekera</div>
                    <div className="chairmanCIC-text2">Chairman</div>
                  </div>
                  <div className="chairmanCIC-divider" />
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}

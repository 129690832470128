import React, { useEffect } from "react";
import "./aboutBetterFuture.css";
import Aos from "aos";
import "aos/dist/aos.css";
import MissionImage from "../../images/MissionNew.png";
import VisionImage from "../../images/VisionNew.png";
import MainImage from "../../images/ani.webp";

export default function AboutBetterFuture() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      {/* <div className="betterFuture-layout">
        <div
          className="betterFuture-title"
          data-aos="fade-down"
          data-aos-duration="700"
        >
          <h1 className="betterFuture-title-h1">Building a Better Future</h1>
        </div>

        <div className="betterFuture-cards-container">
          <div
            className="betterFuture-card"
            data-aos="fade-right"
            data-aos-duration="700"
          >
            <div className="betterFuture-content">
              <div className="betterFuture-front1">
                <div className="header-front"></div>
                <div className="icon-front-1">
                  <img src={VisionImage} alt="Vission Image" />
                </div>
                <h2 className="betterFuture-front-h2">Our Vision</h2>
              </div>
              <div className="betterFuture-back betterFuture-back1">
                <div className="header-back"></div>
                <div className="icon-back-1">
                  <img src={VisionImage} alt="Mission Image" />
                </div>
                <div className="card-content-back">
                  <p className="betterFuture-back-p">
                    To be the most respected and admired corporate for the
                    positive impact we make on society by nurturing the lives of
                    those we touch.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="betterFuture-card"
            data-aos="fade-left"
            data-aos-duration="700"
          >
            <div className="betterFuture-content">
              <div className="betterFuture-front2">
                <div className="header-front"></div>
                <div className="icon-front">
                  <img src={MissionImage} alt="Mission Image" />
                </div>
                <h2 className="betterFuture-front-h2">Our Mission</h2>
              </div>
              <div className="betterFuture-back betterFuture-back2">
                <div className="header-back"></div>
                <div className="icon-back">
                  <img src={MissionImage} alt="Mission Image" />
                </div>
                <div className="card-content-back">
                  <p className="betterFuture-back-p">
                    Harnessing science and modern technology, we will provide
                    solutions of superior quality which are efficient and safe.
                    We will build rewarding and lasting relationships with our
                    stakeholders and be a significant entity in every sector we
                    operate.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className='card'>
        <div className="better-future-image-container">
          <img src={MainImage} alt="" />
        </div>
        <div className="gradient">
          <h1 className="betterFuture-title-h1">Building a Better Future</h1>
          <h2>OUR <span className="better-future-bold-tag">VISION</span></h2>
          <p className="betterFuture-back-p">
            To be the most respected and admired corporate for the
            positive impact we make on society by nurturing the lives of
            those we touch.
          </p>

          <div className="better-future-underline"></div>

          <h2>OUR <span className="better-future-bold-tag">MISSION</span></h2>
          <p className="betterFuture-back-p-2">
            Harnessing science and modern technology, we will provide
            solutions of superior quality which are efficient and safe.
            We will build rewarding and lasting relationships with our
            stakeholders and be a significant entity in every sector we
            operate.
          </p>
        </div>
      </div>
    </div>
  );
}

// <div className="betterFuture-layout-364">
//             <div className="betterFuture-section-title">
//               <div className="betterFuture-content6">
//                 <div className="betterFuture-heading" data-aos="fade-down" data-aos-duration="700">
//                   Building a Better Future
//                 </div>
//               </div>
//             </div>
//             <div className="betterFuture-container3">
//               <div className="betterFuture-row">
//                 <div className="betterFuture-card" data-aos="fade-right" data-aos-duration="700">
//                   <div className="betterFuture-content7">
//                       <div className="betterFuture-content-top">
//                         <div className="betterFuture-date">Our Vision</div>
//                         <div className="betterFuture-text4">
//                           To be the most respected and admired corporate for the
//                           positive impact we make on society by nurturing the
//                           lives of those we touch.
//                         </div>
//                       </div>
//                   </div>
//                 </div>
//                 <div className="betterFuture-card" data-aos="fade-left" data-aos-duration="700">
//                   <div className="betterFuture-content7">
//                       <div className="betterFuture-content-top">
//                         <div className="betterFuture-date">Our Mission</div>
//                         <div className="betterFuture-text4">
//                           Harnessing science and modern technology, we will
//                           provide solutions of superior quality which are
//                           efficient and safe. We will build rewarding and
//                           lasting relationships with our stakeholders and be a
//                           significant entity in every sector we operate.
//                         </div>
//                       </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

import React from "react"
// import Header from "../components/header"
// import Footer from "../components/footer"
import AboutMainBanner from "../templates/AboutUs/aboutMainBanner"
import AboutCIC from "../templates/AboutUs/aboutCIC"
import AboutChairman from "../templates/AboutUs/aboutChairman"
import AboutBetterFuture from "../templates/AboutUs/aboutBetterFuture"
import AboutValues from "../templates/AboutUs/aboutValues"
import AboutBrandStory from "../templates/AboutUs/aboutBrandStory"
// import AboutDirectors from "../templates/AboutUs/aboutDirectors"
// import AboutManagement from "../templates/AboutUs/aboutManagement"
import AboutHistory1 from "../templates/AboutUs/aboutHistory1"
import AboutHistory2 from "../templates/AboutUs/aboutHistory2"
// import AboutManagement1 from "../templates/AboutUs/aboutManagement1"
import AboutManagement2 from "../templates/AboutUs/aboutManagement2"
import AboutDirectors1 from "../templates/AboutUs/aboutDirectors1"
// import Layout from "../components/layout"

export default function AboutUs() {
  return (
    <div>
      {/* <Header /> */}
      {/* <Layout> */}
      <AboutMainBanner />
      <AboutCIC />
      <AboutChairman />
      <AboutBetterFuture />
      <AboutValues />
      {/* <AboutHistory1 /> */}
      <AboutHistory2 />
      <AboutBrandStory />
      {/* <AboutDirectors /> */}
      <AboutDirectors1 />
      {/* <AboutManagement1 /> */}
      <AboutManagement2 />
      {/* <AboutManagement /> */}
      {/* </Layout> */}
      {/* <Footer /> */}
    </div>
  )
}
